import { useState, useEffect } from "react";
import { projectStorage } from "../config/firebase";

const useStorage = (file, childName) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  useEffect(() => {
    if (url === 100) {
      setUrl(0);
    }
  }, [url]);

  useEffect(() => {
    //References
    if (file !== null) {
      const imagesRef = projectStorage.ref(childName).child(file.name);

      imagesRef.put(file).on(
        "state_changed",
        (snap) => {
          let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
          setProgress(percentage);
        },
        (err) => {
          setError(error);
        },
        async () => {
          const url = await imagesRef.getDownloadURL();
          setUrl(url);
        }
      );
    }
  }, [file]);

  return { progress, url, error };
};

export default useStorage;
