import { motion } from "framer-motion";
import React, { useEffect } from "react";
import useStorage from "../../hooks/useStorage";

export const ProgressBar = ({ file, setFile }) => {
  const { url, progress } = useStorage(file);

  useEffect(() => {
    if (url !== null) {
      setFile(null);
    }
  }, [url, setFile]);

  return (
    <motion.div
      initial={{ width: 0 }}
      animate={{ width: progress + "%" }}
      style={{
        marginTop: 5,
        marginBottom: 5,
        height: 2,
        background: "red",
        display: url ? "none" : "block",
      }}
    ></motion.div>
  );
};
