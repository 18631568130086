import styled from "styled-components";
import tw from "twin.macro";

export const Button = styled.button`
  ${tw`text-gray-800 hover:shadow hover:text-gray-900 border-2 border-yellow-300 font-medium text-base rounded`}
  ${(props) => (props.long ? tw`px-12 py-2` : tw`px-4 py-1`)}
  ${(props) => (props.primary ? tw`bg-yellow-300` : tw``)}
  margin: ${({ margin }) => (margin ? margin : "0")};
`;

export const CTAButton = styled(Button)`
  ${(props) =>
    props.author ? tw`bg-yellow-300 border-none` : tw`bg-green-300 border-none`}
`;
