import React from "react";
import heroImg from "../../images/hero-image.svg";
import blob from "../../images/blob.svg";
import { Button } from "../common/Button";
import styled from "styled-components";
import tw from "twin.macro";
import { Link } from "react-router-dom";

export const HeroWrapper = styled.section`
  ${tw`flex flex-col-reverse sm:flex-row items-center relative w-full justify-between my-4  container mx-auto`}

  div {
    ${tw`w-full sm:w-1/2 ml-12 mr-4 my-8`}
  }

  h1 {
    ${tw`text-5xl mx-auto font-display leading-none font-bold`}
    width: 100%;

    @media (max-width: 1024px) {
      ${tw`text-3xl`}
    }
    @media (max-width: 400px) {
      ${tw`text-2xl mt-16`}
    }
  }

  p {
    ${tw`font-display text-base`}
  }
`;

export const HeroComponent = () => {
  return (
    <HeroWrapper>
      <div>
        <h1>
          Connecting <span className="text-yellow-400">Authors</span> <br />
          &amp; <span className="text-green-400">Influencers</span>
        </h1>
        <p>in the digital reading space</p>
        <Link to="/information">
          <Button long primary margin="1.3rem 0 0 0">
            Learn More
          </Button>
        </Link>
      </div>
      <img className="sm:w-1/2 w-11/12" src={heroImg} alt="landing hero"></img>
      <img
        className="absolute sm:w-3/4 w-full transform sm:translate-x-40 -translate-y-52 sm:-translate-y-40 -z-10 right-0"
        src={blob}
        alt=""
      ></img>
    </HeroWrapper>
  );
};
