import { motion } from "framer-motion";
import React, { useState, useContext, useEffect } from "react";
import {
  CampaignImage,
  CampaignSingle,
  CampaignsWrapper,
  OnTourGrid,
} from "./elements";
import { firebase } from "../../config/firebase";
import { AppContext, CampaignContext } from "../../store/context";
import { ActiveCampaign, Button } from "../common";
import { Link } from "react-router-dom";
import { projectFireStore } from "../../config/firebase";
import SEO from "../common/SEO";
import { Loader } from "../common/Loader";

export const CampaignsComponent = () => {
  const { setCurrentCampaign, books, isLoading } = useContext(CampaignContext);
  const [isLoggedin] = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isLoading) return <Loader />;

  const toKebabCase = (str) =>
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join("-");
  return (
    <>
      {isLoggedin && (
        <div className="flex container justify-between p-2">
          <Link
            to="/campaigns/add"
            className="rounded-full border w-12 h-12 hover:bg-yellow-100 text-center grid place-items-center"
          >
            <h1 className="text-3xl">+</h1>
          </Link>
          <Button primary onClick={() => firebase.auth().signOut()}>
            Logout
          </Button>
        </div>
      )}
      <CampaignsWrapper layout>
        <SEO
          pageMeta={{
            title: "Campaigns",
            keywords: ["campaigns", "books on tour", "tour"],
            description: "A list of campaigns we have carried out till now.",
          }}
        />

        {console.log(books.length)}
        {books.length === 0 && (
          <h2 className="text-2xl text-center pt-20">Coming soon..</h2>
        )}

        {books.map((book, key) => (
          <ActiveCampaign
            key={key}
            onClick={() => {
              setCurrentCampaign(book.id);
            }}
            to={`/campaigns/${toKebabCase(book.title)}`}
            book={book}
          >
            <CampaignsGrid book={book} />
          </ActiveCampaign>
        ))}
      </CampaignsWrapper>
    </>
  );
};

export const CampaignsGrid = ({ book }) => {
  const shortDescription = book.description;
  const shorted =
    typeof shortDescription === "string" && shortDescription.slice(0, 100);
  const [campaign, setCampaign] = useState(null);

  useEffect(() => {
    projectFireStore
      .collection("campaigns")
      .doc(book.id)
      .onSnapshot((snapshot) => {
        if (snapshot.data()) setCampaign(snapshot.data());
      });
  }, []);

  let startDate = campaign && new Date(campaign.startTime).setHours(0, 0, 0, 0);
  let endDate = campaign && new Date(campaign.endTime).setHours(24, 0, 0, 0);
  let todayDate = Date.now();
  let tourStatus = "";

  if (todayDate > endDate) {
    tourStatus = "expired";
  } else if (todayDate < startDate) {
    tourStatus = "upcoming";
  } else if (startDate <= todayDate <= endDate) {
    tourStatus = "on";
  } else tourStatus = "not found";

  console.log(book.title, new Date(startDate), new Date(endDate));

  return (
    <CampaignSingle>
      <OnTourGrid onTour={tourStatus} color="#9ae7ac">
        <span className="dot" />
        <p className="text">
          {tourStatus === "upcoming"
            ? "UPCOMING"
            : tourStatus === "on"
            ? "TOUR"
            : "EXPIRED"}
        </p>
      </OnTourGrid>
      <CampaignImage src={book.coverUrl} />
      <motion.div
        layout
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="m-4 text-left"
      >
        <h1 className="text-2xl text-gray-900 font-bold">{book.title}</h1>
        <p className="text-md text-gray-800 mt-3 max-w-xs">
          {`${shorted}...`}
          <p className="text-xs uppercase text-right read-more">Read More</p>
        </p>
      </motion.div>
    </CampaignSingle>
  );
};
