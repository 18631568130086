import React from "react";

import { AiOutlineUser, AiFillBook } from "react-icons/ai";
import { BsGearWideConnected } from "react-icons/bs";
import { IconContext } from "react-icons";

const data = [
  {
    text: "We organize customized book tours",
    description:
      "Let us the work of promoting your book while you relax and write.",
    icon: <AiOutlineUser size={56} className="mx-auto" />,
  },
  {
    text: "Take your book to your target readers",
    description:
      "We organize customized book campaigns on social media to maximize the reach of your book ",
    icon: <AiFillBook size={56} className="mx-auto" />,
  },
  {
    text: "Connect with genuine book influencers",
    description:
      "Let us the work of promoting your book while you relax and write.",
    icon: <BsGearWideConnected size={56} className="mx-auto" />,
  },
];

export const WhatWeDoElement = ({ text, icon }) => {
  return (
    <div className="h-48 w-48 rounded-xl cursor-default border-2 border-yellow-400 hover:shadow-md transform hover:-translate-y-0.5 m-3 flex flex-col justify-around items-center">
      <IconContext.Provider value={{ color: "#2D3748" }}>
        <span>{icon}</span>
      </IconContext.Provider>
      <h2 className="font-semibold text-sm font-display text-gray-700">
        {text}
      </h2>
    </div>
  );
};

export const WhatWeDoSection = () => {
  return (
    <div className="text-center max-w-3xl mx-auto mb-10 sm:my-20">
      <h1 className="text-gray-800 mb-4 text-xl font-bold font-display">
        WHAT WE DO
      </h1>
      <div className="flex justify-around flex-wrap items-center">
        {data.map((item, key) => (
          <WhatWeDoElement key={key} text={item.text} icon={item.icon} />
        ))}
      </div>
    </div>
  );
};
