import { ResourceComponent } from "../components/resources/ResourceComponent";
import React from "react";
import SEO from "../components/common/SEO";

const Resources = () => {
  return (
    <>
      <SEO
        pageMeta={{
          title: "Resources",
          keywords: ["resources", "books on tour", "tour"],
          description: "A list of resources to get started with book reviews.",
        }}
      />
      <div className="mt-12 w-11/12 container">
        <h1 className="text-gray-700 text-2xl text-center">RESOURCES</h1>
        <p className=" text-center mb-16">
          Find amazing resources to learn awesome writing tips
        </p>
        <ResourceComponent />
      </div>
    </>
  );
};

export default Resources;
