import { Form } from "formik";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

export const FormWrapper = styled(Form)`
  margin: 3rem 0;
  main {
    background: rgba(255, 255, 255, 0.25);
    box-shadow: 0 1px 4px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(12px);
    padding: 1.5rem;
    border-radius: 1rem;
    width: 60rem;
    max-width: 65rem;
    margin: 3rem auto;
  }
`;

export const FormInformationWrapper = styled.section`
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 2fr 3fr;
`;

export const AuthorWrapper = styled(motion.div)`
  margin: 2rem 0;
  ${tw`flex justify-around`}
`;

export const StyledInputFile = ({ field, ...props }) => {
  return <input type="file" {...field} {...props} />;
};
