import React, { useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";

const Title = styled.p`
  ${tw`text-gray-800 font-bold my-3 `}
`;
const Paragraph = styled.p`
  ${tw`text-gray-700 font-normal`}
`;

export const Policy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="max-w-2xl w-10/12 mx-auto mt-8">
      <p>
        <Title>This Privacy Policy applies to the Thebookpundits.com</Title>
        <Paragraph>
          Thebookpundits.com recognises the importance of maintaining your
          privacy. We value your privacy and appreciate your trust in us. This
          Policy describes how we treat user information we collect on
          www.thebookpundits.com and other offline sources. This Privacy Policy
          applies to current and former visitors to our website and to our
          online customers. By visiting and/or using our website, you agree to
          this Privacy Policy.
        </Paragraph>
        <Title>Information we collect</Title>
        <Paragraph>
          Contact information. We might collect your name, email, mobile number,
          phone number, street, city, state, pincode, country and ip address.
        </Paragraph>{" "}
        Information you post. We collect information you post in a public space
        on our website or on a third-party social media site belonging to
        thebookpundits.com Demographic information. We may collect demographic
        information about you, tours you like, tours you intend to participate
        in, or any other information provided by you during the use of our
        website. We might collect this as a part of a survey also. Other
        information. If you use our website, we may collect information about
        your IP address and the browser you're using. We might look at what site
        you came from, duration of time spent on our website, pages accessed or
        what site you visit when you leave us. We might also collect the type of
        mobile device you are using, or the version of the operating system your
        computer or device is running.{" "}
        <Title>We collect information in different ways.</Title>
        We collect information directly from you. We collect information
        directly from you when you register for a tour. We also collect
        information if you post a comment on our websites or ask us a question
        through phone or email. We collect information from you passively. We
        use tracking tools like Google Analytics, Google Webmaster, browser
        cookies and web beacons for collecting information about your usage of
        our website. We get information about you from third parties. For
        example, if you use an integrated social media feature on our websites.
        The third-party social media site will give us certain information about
        you. This could include your name and email address. Use of your
        personal information We use information to contact you: We might use the
        information you provide to contact you for confirmation of a tour on our
        website or for other promotional purposes. We use information to respond
        to your requests or questions. We might use your information to confirm
        your registration for a tour. We use information to improve our products
        and services. We might use your information to customize your experience
        with us. This could include displaying content based upon your
        preferences. We use information to look at site trends and customer
        interests. We may use your information to make our website and products
        better. We may combine information we get from you with information
        about you we get from third parties. We use information for security
        purposes. We may use information to protect our company, our customers,
        or our websites. We use information for marketing purposes. We might
        send you information about special promotions or offers. We might also
        tell you about new features or products. These might be our own offers
        or products, or third-party offers or products we think you might find
        interesting. Or, for example, if you register with us we'll enroll you
        in our newsletter. We use information to send you transactional
        communications. We might send you emails or SMS about your tour. We use
        information as otherwise permitted by law.{" "}
        <Title>Sharing of information with third-parties</Title> We will share
        information with third parties who perform services on our behalf. We
        share information with vendors who help us manage our online
        registration process or payment processors or transactional message
        processors. Some vendors may be located outside of India. We will share
        information with the tour organizers. We share your information with
        event organizers and other parties responsible for fulfilling the
        obligation. The tour organizers and other parties may use the
        information we give them as described in their privacy policies. We will
        share information with our business partners. This includes a third
        party who provides or sponsors a tour. Our partners use the information
        we give them as described in their privacy policies. We may share
        information if we think we have to in order to comply with the law or to
        protect ourselves. We will share information to respond to a court order
        or subpoena. We may also share it if a government agency or
        investigatory body requests. Or, we might also share information when we
        are investigating potential fraud. We may share information with any
        successor to all or part of our business. For example, if part of our
        business is sold we may give our customer list as part of that
        transaction. We may share your information for reasons not described in
        this policy. We will tell you before we do this.{" "}
        <Title>Email Opt-Out</Title> You can opt out of receiving our marketing
        emails. To stop receiving our promotional emails, please email
        unsubscriber@bookmyseats.in. It may take about ten days to process your
        request. Even if you opt out of getting marketing messages, we will
        still be sending you transactional messages through email and SMS about
        your tours. <Title>Third party sites</Title> If you click on one of the
        links to third party websites, you may be taken to websites we do not
        control. This policy does not apply to the privacy practices of those
        websites. Read the privacy policy of other websites carefully. We are
        not responsible for these third party sites.{" "}
        <Title>Updates to this policy</Title> This Privacy Policy was last
        updated on 12.12.2014. From time to time we may change our privacy
        practices. We will notify you of any material changes to this policy as
        required by law. We will also post an updated copy on our website.
        Please check our site periodically for updates.{" "}
        <Title>Jurisdiction</Title> If you choose to visit the website, your
        visit and any dispute over privacy is subject to this Policy and the
        website's terms of use. In addition to the foregoing, any disputes
        arising under this Policy shall be governed by the laws of India.
      </p>
    </div>
  );
};
