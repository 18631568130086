import React from "react";
import AuthorImage from "../../images/how-it-works-author.svg";
import ReviewerImage from "../../images/how-it-works-reviewer.svg";

export const HowItWorks = ({ author, setAuthor, data }) => {
  return (
    <div
      className={`${
        author ? data.author.color : data.reviewer.color
      } text-gray-50 py-8`}
    >
      <div className="container p-4">
        <h1 className="text-3xl mb-4">How it Works</h1>
        <div className="flex sm:flex-row flex-col-reverse justify-around items-center mx-auto">
          <div className="w-full sm:w-3/4 max-w-xl">
            <ol className="list-decimal px-3">
              {author
                ? data.author.howItWorks.map((work, key) => (
                    <li className="my-8" key={key}>
                      {work.title}
                    </li>
                  ))
                : data.reviewer.howItWorks.map((work, key) => (
                    <li key={key} className="my-8">
                      {work.title}
                    </li>
                  ))}
            </ol>
          </div>
          <div className="mx-4">
            <img
              className="w-1/2 mx-auto"
              src={author ? AuthorImage : ReviewerImage}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};
