import React, { useEffect } from "react";
import about from "../images/about.svg";
import { data } from "../components/about/data";
import { CTAComponent } from "../components/common";
import SEO from "../components/common/SEO";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <SEO
        pageMeta={{
          title: "About",
          keywords: ["about", "books on tour", "tour"],
          description: "About bookpundits",
        }}
      />
      <div className="container p-2">
        <section className="flex items-center flex-col md:flex-row">
          <div className="max-w-xl mx-auto w-11/12">
            <h1 className="text-2xl mt-8 mb-4 text-gray-800 uppercase font-bold">
              About Book Pundits
            </h1>
            <p className="text-gray-900 leading-7">
              We are a Book Campaign platform. Our motto is to connect Authors
              with their niche readers and reliable authentic Influencers. We
              understand every book has a different audience, so we plan multi
              stop blog tours, to showcase and take your book to a wider
              audience. We offer customized book tours and campaigns for your
              book.
            </p>
          </div>
          <img className="w-full sm:w-1/2" src={about} alt="about hero" />
        </section>

        <section className="my-8">
          <h1 className="text-2xl text-center text-gray-800 font-bold my-12">
            SERVICES WE PROVIDE
          </h1>
          <div className="flex flex-wrap justify-center items-center">
            {data.map((service) => (
              <div className="sm:mb-2 mb-8 transform hover:-translate-y-1 group">
                <svg
                  version="1.1"
                  baseProfile="full"
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute -z-10"
                >
                  <circle
                    className="group-hover:text-gray-300"
                    r="40"
                    fill="#EBF4FF"
                  />
                </svg>
                {service.icon}
                <h1 className="p-4 m-2 w-64 text-center rounded-lg">
                  {service.name}
                </h1>
              </div>
            ))}
          </div>
        </section>
      </div>
      <CTAComponent author />
    </>
  );
};

export default About;
