import { motion } from "framer-motion";
import React, { useState } from "react";
import SEO from "../components/common/SEO";
import { InformationTemplate } from "../components/information";

const Information = () => {
  const [author, setAuthor] = useState(true);
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <SEO
        pageMeta={{
          title: "Information",
          keywords: ["information", "author", "reviewer"],
          description:
            "Information about the how can we help you with connecting author or reviewers.",
        }}
      />
      <InformationTemplate author={author} setAuthor={setAuthor} />
    </motion.div>
  );
};

export default Information;
