import { motion } from "framer-motion";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { projectFireStore } from "../../config/firebase";
import { AppContext, CampaignContext } from "../../store/context";
import { Button } from "../common";
import { Loader } from "../common/Loader";
import SEO from "../common/SEO";
import { ContentRating } from "../edit/ContentRating";
import { Span, StyledImg } from "./elements";
import personPlaceholder from "../../images/person-placeholder-300x300.jpg";
import { FaFacebook, FaTwitter, FaWhatsapp, FaLink } from "react-icons/fa";
import {
  WhatsappShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import { IconContext } from "react-icons/lib";

export const CampaignTemplate = () => {
  const {
    currentCampaign,
    setCurrentCampaign,
    formData,
    setFormData,
    isLoading,
    setIsLoading,
  } = useContext(CampaignContext);
  const [isLoggedin] = useContext(AppContext);
  const [readMore, setReadMore] = useState(false);
  const [author, setAuthor] = useState(null);
  const [campaign, setCampaign] = useState(null);
  const [book, setBook] = useState(null);
  const [copyLink, setCopyLink] = useState(false);
  let history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const count = 1000;
  const extraString = (text, count) => {
    return text.slice(0, count);
  };

  const toKebabCase = (str) =>
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join("-");

  const deleteData = () => {
    let confirmDelete = window.confirm(
      `Are you sure you want to delete "${book.title}"?`
    );
    const deleteBookCampaignAuthor = () => {
      setIsLoading(true);
      projectFireStore
        .collection("books")
        .doc(currentCampaign)
        .delete()
        .then(() => history.replace("/campaigns"));
      projectFireStore.collection("bookAuthor").doc(currentCampaign).delete();
      projectFireStore
        .collection("campaigns")
        .doc(currentCampaign)
        .delete()
        .then(() => setIsLoading(false))
        .catch((e) => console.log(e.value));
      history.replace("/campaigns");
    };
    confirmDelete && deleteBookCampaignAuthor();
  };

  let data = {
    book: "",
    author: "",
    campaign: "",
  };

  useEffect(() => {
    setIsLoading(true);
    projectFireStore
      .collection("books")
      .where("slug", "==", window.location.pathname.slice(11))
      .get()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          setCurrentCampaign(doc.id);
          setIsLoading(false);
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }, []);

  useEffect(() => {
    const getData = async (title) => {
      if (title && title !== "") {
        projectFireStore
          .collection("books")
          .doc(title)
          .onSnapshot((snapshot) => {
            data.book = snapshot.data();
            setBook(snapshot.data());
          });
        projectFireStore
          .collection("bookAuthor")
          .doc(title)
          .onSnapshot((snapshot) => {
            data.author = snapshot.data();
            setAuthor(snapshot.data());
          });
        projectFireStore
          .collection("campaigns")
          .doc(title)
          .onSnapshot((snapshot) => {
            data.campaign = snapshot.data();
            setCampaign(snapshot.data());
          });
      }
    };
    currentCampaign && getData(currentCampaign);
    setFormData(data);
    return () => {
      getData(currentCampaign);
    };
  }, [currentCampaign]);

  let differenceDate = 0;
  let oneDay = 1000 * 60 * 60 * 24;
  let startDate = campaign && new Date(campaign.startTime).setHours(0, 0, 0, 0);
  let endDate = campaign && new Date(campaign.endTime).setHours(24, 0, 0, 0);
  let todayDate = Date.now();
  differenceDate = (endDate - startDate) / oneDay;
  let todayDateStart = (todayDate - startDate) / oneDay;
  let todayDateEnd = Math.round((endDate - todayDate) / oneDay);

  const genreString = book ? book.genre : "";
  const genreSeparate = genreString.split(",");
  let tourStatus = "";

  if (todayDate > endDate) {
    tourStatus = "expired";
  } else if (todayDate < startDate) {
    tourStatus = "upcoming";
  } else if (startDate <= todayDate <= endDate) {
    tourStatus = "on";
  } else tourStatus = "not found";

  const progress =
    tourStatus === "on" ? (todayDateStart / differenceDate) * 100 : 100;

  console.log(progress);

  if (isLoading) return <Loader />;

  const textClass = "text-md text-gray-900 mb-3";

  return (
    <>
      {book && (
        <motion.div className="max-w-5xl my-3 w-11/12 mx-auto overflow-hidden">
          <SEO
            pageMeta={{
              title: book && book.title,
              keywords: ["book", "booktour", "influencer"],
              description: book && book.description,
              img: book && book.coverUrl,
              url: window.location.href,
            }}
          ></SEO>
          <div className="p-2 m-2">
            <div className="flex items-center mb-4">
              <Link to="/campaigns">&#60; BACK</Link>
              {isLoggedin && (
                <div className="flex w-48 ml-auto items-center justify-between">
                  <Button
                    onClick={() => {
                      formData &&
                        history.push(
                          `/campaigns/${toKebabCase(book.title)}/update`
                        );
                    }}
                  >
                    UPDATE
                  </Button>

                  <Button primary onClick={() => deleteData()}>
                    DELETE
                  </Button>
                </div>
              )}
            </div>

            <div className="w-full relative rounded-xl border p-2">
              <motion.div
                className="rounded-md"
                initial={{ width: 0 }}
                animate={{
                  width:
                    progress > 100
                      ? 100 + "%"
                      : progress === 0
                      ? 10 + "%"
                      : progress + "%",
                }}
                style={{
                  height: 30,
                  background:
                    tourStatus === "on"
                      ? "linear-gradient(to right, #f12711, #f5af19)"
                      : tourStatus === "upcoming"
                      ? "#e8e24e"
                      : tourStatus === "expired"
                      ? "#dad4d2"
                      : "#aaa",
                }}
              ></motion.div>
              {campaign ? (
                <p className="absolute w-full text-sm font-medium inset-0 text-center mt-3">
                  {tourStatus === "on"
                    ? `${todayDateEnd} ${
                        todayDateEnd === 1 ? "DAY" : "DAYS"
                      } REMAINING`
                    : tourStatus === "upcoming"
                    ? "UPCOMING TOUR"
                    : "EXPIRED TOUR"}
                </p>
              ) : (
                ""
              )}
            </div>

            {campaign && campaign.startTime ? (
              <div>
                <div className="flex flex-between text-gray-500 text-xs w-full my-2">
                  <h1>{new Date(campaign.startTime).toDateString()}</h1>
                  <h1 className="ml-auto">
                    {new Date(campaign.endTime).toDateString()}
                  </h1>
                </div>
              </div>
            ) : (
              <p></p>
            )}
            <h1 className="text-4xl text-gray-900 font-bold text-center my-4">
              {book && book.title}
            </h1>

            <div className="flex justify-around items-center max-w-xs mx-auto mb-6">
              <IconContext.Provider value={{ color: "gray" }}>
                <FacebookShareButton url={window.location.href}>
                  <FaFacebook />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <FaTwitter />
                </TwitterShareButton>
                <WhatsappShareButton url={window.location.href}>
                  <FaWhatsapp />
                </WhatsappShareButton>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    setTimeout(() => {
                      setCopyLink((prev) => !prev);
                    }, 1000);
                    setCopyLink((prev) => !prev);
                  }}
                  className={` inline-flex items-center p-1 rounded-sm cursor-pointer`}
                >
                  <FaLink color={copyLink ? "#43AA8B" : undefined} />
                  <p className={`ml-2 text-gray-600 text-xs w-16`}>
                    {copyLink ? "copied!" : "copy link"}
                  </p>
                </div>
              </IconContext.Provider>
            </div>
            <hr />
          </div>
          <>
            {book && (
              <>
                <div className="sm:flex justify-center sm:justify-start mx-auto">
                  <div className="w-full mx-auto md:w-80">
                    <img
                      className="md:w-full w-11/12 mx-auto max-w-md rounded mb-4"
                      src={book.coverUrl}
                      alt="book cover"
                    ></img>
                    <section className="flex items-start sm:flex-col flex-row justify-around flex-wrap">
                      <div className="w-1/2 sm:w-full">
                        <Span>RELEASE DATE</Span>
                        <p className={`${textClass} text-sm`}>
                          {new Date(book.releaseDate).toDateString().slice(4)}
                        </p>
                      </div>
                      <div className="w-1/2 sm:w-full">
                        <Span>GENRE</Span>
                        <div className="flex flex-wrap mb-4">
                          {" "}
                          {genreSeparate.map((genre, key) => (
                            <p
                              className={`p-2 m-1 rounded bg-red-600 text-xs text-gray-50`}
                              key={key}
                            >
                              {genre}
                            </p>
                          ))}
                        </div>
                      </div>
                      <div className="w-1/2 sm:w-full">
                        <Span>PUBLISHER</Span>
                        <p className={`${textClass} text-sm`}>
                          {" "}
                          {book.publisher}
                        </p>
                      </div>
                      <div className="w-1/2 sm:w-full">
                        <Span>BOOK FORMAT</Span>
                        <p className={`${textClass} text-sm`}>
                          {" "}
                          {book.bookFormat === "ebook"
                            ? "E-Book"
                            : book.bookFormat === "hardCopy"
                            ? "Hardback Copy"
                            : book.bookFormat === "ebook-and-hardCopy"
                            ? "Ebook and Hardback"
                            : book.bookFormat === "paperBack"
                            ? "Paperback only"
                            : book.bookFormat === "ebook-and-paperBack"
                            ? " Ebook & Paperback"
                            : "select one"}
                        </p>
                      </div>
                      <div className="w-full ">
                        <Span margin="0 0 0.3rem 0">Content Rating</Span>
                        <ContentRating option={book.contentRating} />
                      </div>
                    </section>
                  </div>

                  <div className="sm:ml-8 sm:w-3/4 w-full max-w-3xl">
                    <div className="mt-6 sm:mt-0">
                      <Span>BOOK DESCRIPTION</Span>
                      <p className={`${textClass} group leading-7`}>
                        {extraString(book.description, count)}
                        {readMore && book.description.slice(count)}
                        {book.description.length > count && (
                          <p
                            className="cursor-pointer group-hover:text-yellow-500 ml-2 inline-block text-xs uppercase group-hover:underline"
                            onClick={() => setReadMore(!readMore)}
                          >
                            Read {readMore ? "Less" : "More"}
                          </p>
                        )}
                      </p>
                    </div>

                    {campaign && campaign.signUpLink && (
                      <div className="mt-4">
                        <a
                          className={`${textClass} m-2`}
                          href={campaign && campaign.signUpLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Button primary>Sign Up as Tour Host</Button>
                        </a>
                      </div>
                    )}

                    <div className="mt-4">
                      <Span>Buy now!</Span>
                      <div className="flex">
                        {book.buyLinks.map((link) => (
                          <a
                            className={`${textClass} m-2`}
                            href={link.link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Button primary>{link.name}</Button>
                          </a>
                        ))}
                      </div>
                    </div>
                    <Span margin="0.8rem 0 0 0">
                      {author && author.second && author.second.name !== ""
                        ? "AUTHORS"
                        : "AUTHOR"}
                    </Span>

                    {author && author.first && (
                      <div className="mt-12 bg-olive-50 rounded-md shadow-md relative mx-auto max-w-3xl">
                        <div className="flex justify-between sm:flex-row flex-col">
                          <StyledImg
                            alt="book author"
                            className="rounded-full absolute -top-10 left-0"
                            src={
                              author.first.imgUrl
                                ? author.first.imgUrl
                                : personPlaceholder
                            }
                          />

                          <div className="m-8 ">
                            <div className="ml-32">
                              <Span>NAME</Span>
                              <p className="font-extrabold text-2xl text-gray-900 mb-4">
                                {author.first.name}
                              </p>
                            </div>
                            <Span>ABOUT</Span>
                            <p className="text-gray-800">
                              {author.first.about}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {author && author.second && author.second.name !== "" && (
                      <div className="mt-20 bg-olive-50 rounded-md shadow-md relative mx-auto max-w-3xl">
                        <div className="flex justify-between sm:flex-row flex-col">
                          <img
                            alt="book author"
                            className="rounded-full w-32 h-32 absolute -top-10 left-0"
                            src={author.second.imgUrl}
                          />
                          <div className="m-8 ">
                            <div className="ml-32">
                              <Span>NAME</Span>
                              <p className="font-extrabold text-2xl text-gray-900 mb-4">
                                {author.second.name}
                              </p>
                            </div>
                            <Span>ABOUT</Span>
                            <p className="text-gray-800">
                              {author.second.about}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {campaign && campaign.tourSchedule.length > 0 && (
                      <div className="mt-16">
                        <Span margin="0 0 0.8rem 0">
                          {campaign &&
                          campaign.tourSchedule &&
                          campaign.tourSchedule.length > 1
                            ? "TOURS"
                            : "TOUR"}
                        </Span>
                        {campaign &&
                          campaign.tourSchedule &&
                          campaign.tourSchedule.map((tour) => (
                            <div className="flex items-center justify-start w-11/12 mx-auto border rounded mb-4 h-full">
                              <div className="m-2 p-2 w-24 text-center leading-none text-gray-900">
                                <h1 className="uppercase">
                                  {new Date(tour.date)
                                    .toDateString()
                                    .slice(4, 7)}
                                  {"  "}
                                  {new Date(tour.date).getDate()}
                                </h1>
                                <h1 className="text-xl">
                                  {new Date(tour.date).getFullYear()}{" "}
                                </h1>
                              </div>

                              <a
                                className="flex group w-full items-center justify-between"
                                href={tour.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <h1 className="p-2 rounded ">{tour.title}</h1>
                                <div className="transform mr-4">
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M13 3L16.293 6.293L9.29297 13.293L10.707 14.707L17.707 7.707L21 11V3H13Z"
                                      className="fill-current  text-gray-400 group-hover:text-yellow-500"
                                    />
                                    <path
                                      d="M19 19H5V5H12L10 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V14L19 12V19Z"
                                      className="fill-current text-gray-400 group-hover:text-yellow-500"
                                    />
                                  </svg>
                                </div>
                              </a>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        </motion.div>
      )}
    </>
  );
};
