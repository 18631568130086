import React, { useEffect } from "react";
import styled from "styled-components";
import tw from "twin.macro";
import data from "./data";

export const ResourceComponent = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="flex flex-wrap justify-center">
      {data.map((resource) => (
        <Resource href={resource.link} target="_blank">
          {resource.image}
          <h1>{resource.heading}</h1>
          <svg
            className="absolute top-0 right-0 w-6"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13 3L16.293 6.293L9.29297 13.293L10.707 14.707L17.707 7.707L21 11V3H13Z"
              className="fill-current  text-gray-400 group-hover:text-yellow-500"
            />
            <path
              d="M19 19H5V5H12L10 3H5C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H19C20.103 21 21 20.103 21 19V14L19 12V19Z"
              className="fill-current text-gray-400 group-hover:text-yellow-500"
            />
          </svg>
        </Resource>
      ))}
    </div>
  );
};

const Resource = styled.a.attrs({
  className: "group",
})`
  ${tw`flex justify-center items-center sm:mx-24 my-4 shadow hover:shadow-md rounded-md p-2 relative`}
  width: 20rem;

  h1 {
    ${tw`text-base font-medium ml-8`}
  }

  svg {
    margin-right: auto;
    margin-bottom: auto;
  }
`;
