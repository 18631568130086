import React, { useContext, useEffect, useState } from "react";
import { projectFireStore, timestamp } from "../../config/firebase";
import { useHistory } from "react-router-dom";
import { FormComponent } from "./Form";
import { CampaignContext } from "../../store/context";
import { v4 as uuidv4 } from "uuid";

const initialValues = {
  book: {
    title: "",
    releaseDate: "",
    slug: "",
    genre: "",
    publisher: "",
    contentRating: "",
    description: "",
    bookFormat: "",
    pages: "",
    buyLinks: [],
  },
  author: {
    first: {
      name: "",
      about: "",
      imgUrl: "",
    },
    second: {
      name: "",
      about: "",
      imgUrl: "",
    },
  },
  campaign: {
    startTime: "",
    endTime: "",
    tourSchedule: [],
    signUpLink: "",
  },
};

export const AddCampaign = () => {
  const [campaignData, setCampaignData] = useState(initialValues);
  const [bookImage, setBookImage] = useState(null);
  const [authorImageFirst, setAuthorImageFirst] = useState(null);
  const [authorImageSecond, setAuthorImageSecond] = useState(null);
  const [showSecondAuthor, setShowSecondAuthor] = useState(false);
  const { currentCampaign, setIsLoading } = useContext(CampaignContext);
  let history = useHistory();

  useEffect(() => {
    campaignData.book.title.length > 0 && addData();
  }, [currentCampaign, campaignData]);

  const addData = async () => {
    setIsLoading(true);
    const { book, author, campaign } = campaignData;
    const bookDoc = uuidv4();
    const { first, second } = author;

    let startDate =
      campaign && new Date(campaign.startTime).setHours(0, 0, 0, 0);
    startDate = new Date(startDate).setHours(0, 0, 0, 0);
    let endDate = campaign && new Date(campaign.endTime).setHours(0, 0, 0, 0);
    endDate = new Date(endDate).setHours(0, 0, 0, 0);
    let todayDate = campaign && Date.now();
    let tourStatus = false;

    if (todayDate > endDate) {
      tourStatus = "expired";
    } else if (todayDate < startDate) {
      tourStatus = "upcoming";
    } else if (startDate <= todayDate <= endDate) {
      tourStatus = "on";
    } else tourStatus = "not found";

    const createdAt = timestamp();

    const bookAuthor =
      first && second.name === ""
        ? { first: { ...first, imgUrl: authorImageFirst } }
        : first && second
        ? {
            first: { ...first, imgUrl: authorImageFirst },
            second: { ...second, imgUrl: authorImageSecond },
          }
        : second;

    const bookAuthorRef = projectFireStore
      .collection("bookAuthor")
      .doc(bookDoc);
    const booksRef = projectFireStore.collection("books").doc(bookDoc);
    const campaignsRef = projectFireStore.collection("campaigns").doc(bookDoc);
    const usersRef = projectFireStore
      .collection("users")
      .doc(toKebabCase(author.first.name));

    const batch = projectFireStore.batch();
    batch.set(bookAuthorRef, {
      ...bookAuthor,
      id: bookDoc,
      createdAt: createdAt,
    });
    batch.set(booksRef, {
      ...book,
      coverUrl: bookImage,
      releaseDate: new Date(book.releaseDate).setHours(0, 0, 0, 0),
      id: bookDoc,
      slug: toKebabCase(book.title),
      createdAt: createdAt,
    });
    batch.set(campaignsRef, {
      ...campaign,
      id: bookDoc,
      onTour: tourStatus,
      createdAt: createdAt,
    });
    batch.set(usersRef, {
      name: first.name,
      about: first.about,
      image: authorImageFirst,
      books: [bookDoc],
      createdAt: createdAt,
    });
    batch.commit().then(() => {
      history.push("/campaigns");
      setIsLoading(false);
    });

    if (second && second.name !== "") {
      projectFireStore
        .collection("users")
        .doc(toKebabCase(second.name))
        .set(second);
    }
  };

  const handleSubmit = async (value, { resetForm }) => {
    setCampaignData(value);
    await addData();
    setBookImage(null);
    setAuthorImageFirst(null);
    setAuthorImageSecond(null);
    resetForm({ value: "" });
  };

  const toKebabCase = (str) =>
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join("-");

  return (
    <FormComponent
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      bookImage={bookImage}
      setBookImage={setBookImage}
      authorImageFirst={authorImageFirst}
      setAuthorImageFirst={setAuthorImageFirst}
      authorImageSecond={authorImageSecond}
      setAuthorImageSecond={setAuthorImageSecond}
      showSecondAuthor={showSecondAuthor}
      setShowSecondAuthor={setShowSecondAuthor}
    />
  );
};
