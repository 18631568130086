import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

export const ToggleWrapper = styled.main`
  ${tw`text-center flex mx-auto cursor-pointer select-none relative rounded`};
  width: 100%;
  z-index: 3;
  position: sticky;
  top: 3.4rem;
  background-color: white;
  overflow: hidden;

  div {
    ${tw`absolute w-1/2 h-full`}
    left: ${(props) => (!props.author ? "0" : "50%")};
    background-color: ${(props) => (!props.author ? "#f5e985" : "#c9f2d1")};
    transition: all 0.2s ease;
    z-index: -1;
  }

  p {
    ${tw`p-2 w-full m-1 rounded text-gray-800 transition duration-100 `}
  }

  .read {
    text-decoration: ${(props) => (!props.author ? "underline" : "")};
  }
  .write {
    text-decoration: ${(props) => (props.author ? "underline" : "")};
  }
`;

export const Toggle = ({ author, setAuthor }) => {
  return (
    <ToggleWrapper author={author} onClick={() => setAuthor((prev) => !prev)}>
      <div />
      <p className="write">
        I <b>write</b> books
      </p>
      <p className="read">
        I <b>read</b> books
      </p>
    </ToggleWrapper>
  );
};
