import { motion } from "framer-motion";
import React from "react";
import { CTAComponent } from "../components/common/CTA";
import SEO from "../components/common/SEO";
import {
  FAQComponent,
  HeroComponent,
  WhatWeDoSection,
} from "../components/landing";

const Landing = () => {
  return (
    <motion.div
      className="overflow-x-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <SEO
        pageMeta={{
          title: "Home",
          keywords: ["author", "reviewer", "booktour"],
          description: "Where authors connect with influencers",
        }}
      ></SEO>
      <HeroComponent />
      <WhatWeDoSection />
      <CTAComponent author />
      <FAQComponent />
    </motion.div>
  );
};

export default Landing;
