import { motion } from "framer-motion";
import React from "react";
import { CampaignsComponent } from "../components/campaign";

const Campaign = ({ match }) => {
  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <CampaignsComponent />
    </motion.div>
  );
};

export default Campaign;
