import React, { useEffect, useState } from "react";
import useStorage from "../../hooks/useStorage";
import { Button } from "../common/Button";
import { ProgressBar } from "../common/ProgressBar";

export const AuthorImageFirst = ({ image, setImage }) => {
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const types = ["image/png", "image/jpeg"];

  const { url } = useStorage(file, "author");
  const hiddenFileInput = React.useRef(null);

  useEffect(() => {
    if (url && url !== "") {
      setImage(url);
    }
  }, [url]);

  const changeHandler = (e) => {
    const selected = e.target.files[0];
    if (selected && types.includes(selected.type)) {
      setFile(selected);
      setError("");
    } else {
      setFile(null);
      setError("Please select an image file (png or jpeg)");
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    hiddenFileInput.current.click();
  };
  const handleRemoveClick = (e) => {
    e.preventDefault();
    setImage(null);
  };

  return (
    <div className="w-full">
      <ProgressBar file={file} setFile={setFile} />
      <div>
        <Button primary onClick={handleClick}>
          😎📷
        </Button>
        {image && <Button onClick={handleRemoveClick}>❌</Button>}
      </div>

      <input
        type="file"
        style={{ display: "none" }}
        ref={hiddenFileInput}
        onChange={changeHandler}
      />
      {error && <h1 className="text-red-300">{error}</h1>}
    </div>
  );
};
