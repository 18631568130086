import firebase from "firebase";
import "firebase/firestore";
import "firebase/firebase-functions";

var firebaseConfig = {
  apiKey: "AIzaSyDhyU_rrNhgWXsXlRq4GJmmi-5NbjGZG-U",
  authDomain: "thebookpundit-cbb29.firebaseapp.com",
  databaseURL: "https://thebookpundit-cbb29.firebaseio.com",
  projectId: "thebookpundit-cbb29",
  storageBucket: "thebookpundit-cbb29.appspot.com",
  messagingSenderId: "344482658830",
  appId: "1:344482658830:web:89645b5f855c9ef0bc9a5f",
  measurementId: "G-9K6HBNJWR8",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const projectStorage = firebase.storage();
const projectFireStore = firebase.firestore();
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export { firebase, projectFireStore, projectStorage, timestamp };
