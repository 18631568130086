import React from "react";
import { FaFacebook, FaGoodreads, FaInstagram } from "react-icons/fa";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";

export const FooterWrapper = styled.footer`
  ${tw`text-gray-700 text-xs my-24`}

  ul {
    &:nth-child(1) {
      ${tw`flex max-w-xl font-semibold text-sm justify-around mx-auto`}
    }
    &:nth-child(2) {
      ${tw`flex w-24 justify-around mx-auto my-12`}
    }
  }

  p {
    ${tw`text-xs text-gray-400 text-center`}
  }
`;

const links = [
  {
    name: "About",
    link: "/about",
  },
  {
    name: "Campaigns",
    link: "/campaigns",
  },
  {
    name: "Resources",
    link: "/resources",
  },
  {
    name: "Policy",
    link: "/policy",
  },
];
const socialLinks = [
  {
    name: "Facebook",
    icon: <FaFacebook size={20} />,
    link: "https://www.facebook.com/thebookpunditspage",
  },
  {
    name: "Instagram",
    icon: <FaInstagram size={20} />,
    link: "https://www.instagram.com/thebookpundits/",
  },
];

export const FooterComponent = () => {
  return (
    <FooterWrapper>
      {/* Links */}
      <ul>
        {links.map((link, key) => (
          <li className="hover:text-yellow-400 text-xs" key={key}>
            <Link to={link.link}>{link.name}</Link>
          </li>
        ))}
      </ul>
      {/* Social Links */}
      <ul>
        {socialLinks.map((link, key) => (
          <li className="hover:text-yellow-400" key={key}>
            <a target="__blank" href={link.link}>
              {link.icon}
            </a>
          </li>
        ))}
      </ul>

      {/* Copyrights */}
      <p className="text-xs">
        © {new Date().getFullYear()} The Book Pundits, All rights reserved.
      </p>
    </FooterWrapper>
  );
};
