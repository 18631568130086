import React from "react";
import E from "../../images/content-rating/E.svg";
import E10plus from "../../images/content-rating/E10plus.svg";
import T from "../../images/content-rating/T.svg";
import AO from "../../images/content-rating/AO.svg";
import RP from "../../images/content-rating/RP.svg";
import M from "../../images/content-rating/M.svg";

export const ContentRating = ({ option }) => {
  switch (option) {
    case "E":
      return <img alt="content rating - everyone" src={E} />;
    case "E10plus":
      return <img alt="content rating - 10plus" src={E10plus} />;
    case "T":
      return <img alt="content rating - teen" src={T} />;
    case "AO":
      return <img alt="content rating - adult only" src={AO} />;
    case "RP":
      return <img alt="content rating - review pending" src={RP} />;
    case "M":
      return <img alt="content rating - mature" src={M} />;
    default:
      return <img alt="content rating - everyone" src={E} />;
  }
};
