export const data = [
  {
    name: "Organize custom book tours",
    icon: (
      <svg
        className="mx-auto"
        width="83"
        height="83"
        viewBox="0 0 83 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M73.8351 3.88598C76.6968 3.88598 79.0166 6.2058 79.0166 9.06745L79.0166 32.384C79.0166 35.2457 76.6968 37.5655 73.8351 37.5655L50.5186 37.5655C47.6569 37.5655 45.3371 35.2457 45.3371 32.384L45.3371 9.06744C45.3371 6.2058 47.6569 3.88598 50.5186 3.88598L73.8351 3.88598Z"
          fill="#F6AD55"
          stroke="#F6AD55"
          stroke-width="2.59073"
        />
        <path
          d="M32.3838 3.88599C35.2454 3.88599 37.5653 6.20581 37.5653 9.06745V32.384C37.5653 35.2457 35.2454 37.5655 32.3838 37.5655H9.0672C6.20556 37.5655 3.88574 35.2457 3.88574 32.384L3.88574 9.06745C3.88574 6.20581 6.20556 3.88599 9.06721 3.88599L32.3838 3.88599Z"
          stroke="#9C4221"
          stroke-width="2.59073"
        />
        <path
          d="M32.3838 45.3377C35.2454 45.3377 37.5653 47.6575 37.5653 50.5192V73.8357C37.5653 76.6974 35.2454 79.0172 32.3838 79.0172H9.06721C6.20556 79.0172 3.88575 76.6974 3.88575 73.8357L3.88575 50.5192C3.88575 47.6575 6.20557 45.3377 9.06721 45.3377L32.3838 45.3377Z"
          stroke="#9C4221"
          stroke-width="2.59073"
        />
        <path
          d="M73.8355 45.3377C76.6971 45.3377 79.017 47.6575 79.017 50.5192L79.017 73.8357C79.017 76.6974 76.6971 79.0172 73.8355 79.0172H50.5189C47.6573 79.0172 45.3374 76.6974 45.3374 73.8357V50.5192C45.3374 47.6575 47.6573 45.3377 50.5189 45.3377H73.8355Z"
          stroke="#9C4221"
          stroke-width="2.59073"
        />
      </svg>
    ),
  },
  {
    name: "Book Editing",
    icon: (
      <svg
        className="mx-auto"
        width="84"
        height="83"
        viewBox="0 0 84 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.28906 81.6084L58.6944 81.6084"
          stroke="#F6AD55"
          stroke-width="2.59073"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M68.2864 1.84105C69.3 0.831247 70.9435 0.831247 71.9572 1.84105L81.5034 11.3506C82.5171 12.3604 82.5171 13.9976 81.5034 15.0074L76.8308 19.6621L65.4492 8.32414C64.4355 7.31434 64.4355 5.67713 65.4492 4.66733L68.2864 1.84105Z"
          fill="#F6AD55"
        />
        <path
          d="M5.66028 80.4899C6.88079 79.882 7.14145 78.254 6.17178 77.2954C5.20893 76.3436 3.59708 76.6112 2.99345 77.8231C2.14274 79.5311 3.95229 81.3406 5.66028 80.4899Z"
          fill="#9C4221"
        />
        <path
          d="M10.9213 58.4015L3.80361 75.2413M10.9213 58.4015L14.8224 62.2876M10.9213 58.4015L62.9353 6.58687M3.80361 75.2413L2.99569 77.1527C2.09089 79.2934 4.25291 81.4471 6.40181 80.5458L8.32061 79.7409M3.80361 75.2413L8.32061 79.7409M25.2252 72.6505L8.32061 79.7409M25.2252 72.6505L21.3241 68.7644M25.2252 72.6505L77.2392 20.8359M21.3241 68.7644L14.8224 62.2876M21.3241 68.7644L73.3381 16.9498M14.8224 62.2876L66.8364 10.473M62.9353 6.58687L65.9822 3.49959C68.0135 1.4761 71.3068 1.47611 73.3381 3.49959L80.2861 10.4209C82.3173 12.4444 82.3173 15.7251 80.2861 17.7486L77.2392 20.8359M62.9353 6.58687L66.8364 10.473M77.2392 20.8359L73.3381 16.9498M66.8364 10.473L73.3381 16.9498"
          stroke="#9C4221"
          stroke-width="2.59073"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    name: "Book proofreading",
    icon: (
      <svg
        className="mx-auto"
        width="84"
        height="83"
        viewBox="0 0 84 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M37.5623 63.0961C37.5623 71.8892 30.2041 79.0174 21.1273 79.0174C12.0506 79.0174 4.69238 71.8892 4.69238 63.0961M37.5623 63.0961C37.5623 54.303 30.2041 47.1747 21.1273 47.1747C12.0506 47.1747 4.69238 54.303 4.69238 63.0961M37.5623 63.0961H46.9537M4.69238 63.0961L4.69238 10.2121C4.69238 6.71842 7.5831 3.88623 11.149 3.88623C14.7148 3.88623 17.6056 6.71842 17.6056 10.2121V15.3211M79.8236 63.0961C79.8236 71.8892 72.4654 79.0174 63.3886 79.0174C54.3119 79.0174 46.9537 71.8892 46.9537 63.0961M79.8236 63.0961C79.8236 54.303 72.4654 47.1747 63.3886 47.1747C54.3119 47.1747 46.9537 54.303 46.9537 63.0961M79.8236 63.0961V10.2121C79.8236 6.71842 76.9329 3.88623 73.367 3.88623C69.8011 3.88623 66.9104 6.71842 66.9104 10.2121V15.3211"
          stroke="#9C4221"
          stroke-width="2.59073"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.3057 22.0214L46.1454 3.88623L42.2593 16.8399H53.9176L35.7825 37.5657L40.2574 22.0214H29.3057Z"
          fill="#F6AD55"
          stroke="#F6AD55"
          stroke-width="2.59073"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
  {
    name: "Develop targeted book promotion strategy",
    icon: (
      <svg
        className="mx-auto"
        width="84"
        height="83"
        viewBox="0 0 84 83"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M52.0239 34.9751C52.0239 32.8288 53.7638 31.089 55.91 31.089H61.0914C63.2377 31.089 64.9775 32.8288 64.9775 34.9751C64.9775 37.1213 63.2377 38.8612 61.0914 38.8612H55.91C53.7638 38.8612 52.0239 37.1213 52.0239 34.9751Z"
          fill="#F6AD55"
        />
        <path
          d="M7.98145 24.6122C7.98145 23.8967 8.5614 23.3168 9.27681 23.3168H14.4583C15.1737 23.3168 15.7536 23.8967 15.7536 24.6122C15.7536 25.3276 15.1737 25.9075 14.4583 25.9075H9.27681C8.5614 25.9075 7.98145 25.3276 7.98145 24.6122Z"
          fill="#F6AD55"
        />
        <path
          d="M67.5683 19.4307C67.5683 18.7153 68.1482 18.1353 68.8636 18.1353H79.2266C79.942 18.1353 80.5219 18.7153 80.5219 19.4307C80.5219 20.1461 79.942 20.7261 79.2266 20.7261H68.8636C68.1482 20.7261 67.5683 20.1461 67.5683 19.4307Z"
          fill="#F6AD55"
        />
        <path
          d="M67.5683 14.2492C67.5683 13.5338 68.1482 12.9539 68.8636 12.9539H79.2266C79.942 12.9539 80.5219 13.5338 80.5219 14.2492C80.5219 14.9646 79.942 15.5446 79.2266 15.5446H68.8636C68.1482 15.5446 67.5683 14.9646 67.5683 14.2492Z"
          fill="#F6AD55"
        />
        <path
          d="M32.5934 59.587C33.3088 59.587 33.8888 60.167 33.8888 60.8824V62.1778V69.95V71.2453C33.8888 71.9607 33.3088 72.5407 32.5934 72.5407C31.878 72.5407 31.298 71.9607 31.298 71.2453V69.95V62.1778V60.8824C31.298 60.167 31.878 59.587 32.5934 59.587Z"
          fill="#F6AD55"
        />
        <path
          d="M35.1841 62.1778C35.8995 62.1778 36.4795 61.5978 36.4795 60.8824C36.4795 60.167 35.8995 59.587 35.1841 59.587H24.8212C24.1058 59.587 23.5258 60.167 23.5258 60.8824C23.5258 61.5978 24.1058 62.1778 24.8212 62.1778H26.1166V69.95H24.8212C24.1058 69.95 23.5258 70.5299 23.5258 71.2453C23.5258 71.9607 24.1058 72.5407 24.8212 72.5407H35.1841C35.8995 72.5407 36.4795 71.9607 36.4795 71.2453C36.4795 70.5299 35.8995 69.95 35.1841 69.95H33.8888H31.298H28.7073V62.1778H31.298H33.8888H35.1841Z"
          fill="#F6AD55"
        />
        <path
          d="M19.6401 16.8396H8.7914C6.19804 16.8396 4.0957 19.1595 4.0957 22.0211V73.8357C4.0957 76.6974 6.19804 79.0172 8.7914 79.0172H19.6401M19.6401 79.0172H40.3659M19.6401 79.0172V3.88599H40.3659V79.0172M40.3659 79.0172H48.1381M40.3659 79.0172V9.06745H48.1381V79.0172M48.1381 79.0172H68.864M48.1381 24.6118H68.864M68.864 24.6118V79.0172M68.864 24.6118V6.47672H74.5312C77.1246 6.47672 79.2269 8.79654 79.2269 11.6582V73.8357C79.2269 76.6974 77.1246 79.0172 74.5312 79.0172H68.864"
          stroke="#9C4221"
          stroke-width="2.59073"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    ),
  },
];
