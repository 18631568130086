import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

export const CampaignsWrapper = styled.main`
  ${tw`container py-16`}
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  flex: 1;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const CampaignImage = styled(motion.img).attrs({
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 100,
  },
})`
  width: 100%;
  height: 20rem;
  object-fit: cover;
  margin: 0 auto;
  background-color: gray;
`;

export const Span = styled.div`
  ${tw`text-xs text-left text-yellow-600 font-bold uppercase w-full`};
  margin: ${(props) => (props.margin ? props.margin : "")};
`;

export const OnTourGrid = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 15px;
  right: 75%;

  .text {
    ${tw`text-gray-700 py-1 pl-32 pr-4 rounded-full bg-green-300 font-bold text-right shadow`};
    ${(props) =>
      props.onTour === "on"
        ? tw`bg-green-300`
        : props.onTour === "upcoming"
        ? tw`bg-olive-300`
        : tw`bg-gray-300`};
    transition: all 0.4s ease;
    transform: translateX(-70px);
    font-size: 0.6rem;
  }
`;

export const CampaignSingle = styled.div`
  &:hover {
    .text {
      transform: translateX(0px);
    }

    .read-more {
      ${tw`underline text-yellow-400`}
    }
  }
`;

export const StyledImg = styled.img`
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  background-color: gray;
`;
