import React, { useEffect } from "react";
import { CTAButton, CTAComponent } from "../common";
import informationAuthor from "./authorHero.svg";
import informationReviewer from "./reviewerHero.svg";
import { data } from "./data";
import { Toggle } from "./ToggleComponent";
import { HowItWorks } from "./HowItWorksElements";
import styled from "styled-components";
import tw from "twin.macro";
import { motion } from "framer-motion";

export const InformationHeroWrapper = styled(motion.section).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
})`
  ${tw`flex flex-col-reverse sm:flex-row-reverse sm:items-center container mx-auto sm:justify-around my-20`}

  .info-hero {
    ${tw`sm:w-1/2 w-full p-3 `}

    h1 {
      ${tw`text-2xl sm:text-4xl font-medium leading-tight  my-4`}
    }

    p {
      ${tw`mt-2 mb-8`}
    }
  }

  img {
    ${tw`block w-3/4 sm:w-1/3 mx-auto`}
  }
`;

export const Perks = ({ author, data }) => {
  const PerksItem = ({ icon, perk }) => {
    return (
      <div className="flex justify-around p-3 my-3 md:my-6 sm:my-0 rounded-md items-center mx-auto max-w-xs">
        <span className="mx-8 w-20">{icon}</span>
        <p className="text-sm w-64 text-left">{perk}</p>
      </div>
    );
  };
  return (
    <div className="flex flex-col sm:flex-row container flex-wrap justify-around mt-2 mb-12">
      {author
        ? data.author.perks.map((perk, key) => (
            <PerksItem key={key} icon={perk.icon} perk={perk.description} />
          ))
        : data.reviewer.perks.map((perk, key) => (
            <PerksItem key={key} icon={perk.icon} perk={perk.description} />
          ))}
    </div>
  );
};

export const InformationTemplate = ({ author, setAuthor }) => {
  const authorData = data.author;
  const reviewerData = data.reviewer;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Toggle author={author} setAuthor={setAuthor} />
      {/* information hero */}
      <InformationHeroWrapper>
        <div className="info-hero">
          <h1>{author ? authorData.heading : reviewerData.heading}</h1>
          <p>{author ? authorData.description : reviewerData.description}</p>
          <a
            href={
              author
                ? "https://docs.google.com/forms/d/e/1FAIpQLScevfmXoA4KrK9jma0_4stATy9MR3dCRqPWlSu5vBU6nBnzJA/viewform"
                : "https://docs.google.com/forms/d/e/1FAIpQLScd3vFijgT-kEpIhrNHFxLX4GMRy5bBUC2zWShIrI135-bUlw/viewform"
            }
            target="_blank"
            rel="noreferrer"
          >
            <CTAButton author={author} long primary>
              {author ? authorData.buttonText : reviewerData.buttonText}
            </CTAButton>
          </a>
        </div>
        <img
          className="w-3/4"
          src={author ? informationAuthor : informationReviewer}
          alt="hero"
        ></img>
      </InformationHeroWrapper>
      {/* Why Work With Us */}
      <h1 className="text-center text-2xl max-w-3xl mx-auto">
        {author ? authorData.middleText : reviewerData.middleText}
      </h1>
      {/* Perks */}
      <Perks author={author} data={data} />
      {/* How it works */}
      <HowItWorks author={author} setAuthor={setAuthor} data={data} />
      <CTAComponent author={author} />
    </div>
  );
};
