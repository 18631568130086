import React, { useContext, useEffect, useState } from "react";
import { projectFireStore } from "../../config/firebase";
import { useHistory } from "react-router-dom";
import { FormComponent } from "./Form";
import { CampaignContext } from "../../store/context";

export const UpdateCampaign = () => {
  const { formData, setFormData } = useContext(CampaignContext);
  const [bookImage, setBookImage] = useState(null);
  const [authorImageFirst, setAuthorImageFirst] = useState(null);
  const [authorImageSecond, setAuthorImageSecond] = useState(null);
  const [showSecondAuthor, setShowSecondAuthor] = useState(false);
  let history = useHistory();
  const { book, author, campaign } = formData;

  let initialValues = {};

  initialValues =
    author && author.second
      ? {
          book,
          author,
          campaign,
        }
      : {
          book,
          author: { first: author.first },
          campaign,
        };

  const updateData = (value2) => {
    const { book, author, campaign } = value2;
    const bookDoc = book.id;
    const { first, second } = author;

    let startDate =
      campaign && new Date(campaign.startTime).setHours(0, 0, 0, 0);
    startDate = new Date(startDate).setHours(0, 0, 0, 0);
    let endDate = campaign && new Date(campaign.endTime).setHours(0, 0, 0, 0);
    endDate = new Date(endDate).setHours(0, 0, 0, 0);
    let todayDate = campaign && Date.now();
    let tourStatus = false;

    if (todayDate > endDate) {
      tourStatus = "expired";
    } else if (todayDate < startDate) {
      tourStatus = "upcoming";
    } else if (startDate <= todayDate <= endDate) {
      tourStatus = "on";
    } else tourStatus = "not found";

    console.log(todayDate, startDate, endDate);
    console.log(startDate <= todayDate <= endDate);

    const bookAuthor =
      first && second
        ? {
            first: { ...first, imgUrl: authorImageFirst },
            second: { ...second, imgUrl: authorImageSecond },
          }
        : first && second && second.name === ""
        ? { first: { ...first, imgUrl: authorImageFirst } }
        : first && { first: { ...first, imgUrl: authorImageFirst } };

    console.log(bookAuthor);

    const bookAuthorRef = projectFireStore
      .collection("bookAuthor")
      .doc(bookDoc);
    const booksRef = projectFireStore.collection("books").doc(bookDoc);
    const campaignsRef = projectFireStore.collection("campaigns").doc(bookDoc);
    const usersRef = projectFireStore
      .collection("users")
      .doc(toKebabCase(author.first.name));

    const batch = projectFireStore.batch();

    batch.update(bookAuthorRef, bookAuthor);
    batch.update(booksRef, {
      ...book,
      releaseDate: new Date(book.releaseDate).setHours(0, 0, 0, 0),
      coverUrl: bookImage,
      slug: toKebabCase(book.title),
    });
    batch.set(
      campaignsRef,
      { ...campaign, id: bookDoc, onTour: tourStatus },
      { merge: true }
    );
    batch.set(
      usersRef,
      {
        name: first.name,
        about: first.about,
        image: authorImageFirst,
        books: [bookDoc],
      },
      { merge: true }
    );
    batch
      .commit()
      .then(() => history.push("/campaigns"))
      .catch((e) => console.log(e));

    if (author.second && author.second.name !== "") {
      projectFireStore
        .collection("users")
        .doc(toKebabCase(author.second.name))
        .set(author.second, { merge: true })
        .then()
        .catch((e) => console.log(e));
    }
  };

  const handleSubmit = (value) => {
    setFormData(value);
    updateData(value);
  };

  const toKebabCase = (str) =>
    str &&
    str
      .match(
        /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
      )
      .map((x) => x.toLowerCase())
      .join("-");

  useEffect(() => {
    if (formData) {
      setBookImage(formData.book.coverUrl);
      setAuthorImageFirst(formData.author.first.imgUrl);
      formData.author.second &&
        setAuthorImageSecond(formData.author.second.imgUrl);
    }
  }, [formData]);

  return (
    <FormComponent
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      bookImage={bookImage}
      setBookImage={setBookImage}
      authorImageFirst={authorImageFirst}
      setAuthorImageFirst={setAuthorImageFirst}
      authorImageSecond={authorImageSecond}
      setAuthorImageSecond={setAuthorImageSecond}
      showSecondAuthor={showSecondAuthor}
      setShowSecondAuthor={setShowSecondAuthor}
    />
  );
};
