import React, { useEffect, useState, useContext } from "react";
import { firebase } from "../../config/firebase";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { Button } from "../common";
import { AppContext } from "../../store/context";

export const LoginWrapper = styled.div`
  ${tw`flex flex-col items-center justify-center mt-48`}
`;

export const LoginComponent = () => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [isLoggedin] = useContext(AppContext);

  let history = useHistory();

  if (isLoggedin) history.push("/campaigns");
  const handleSubmit = (e) => {
    e.preventDefault();
    setCredentials({ email: e.target[0].value, password: e.target[1].value });
  };
  useEffect(() => {
    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        window.location.replace("/campaigns");
      });
  }, [credentials]);

  return (
    <LoginWrapper>
      <form onSubmit={handleSubmit}>
        <label>
          Name:
          <input
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
            type="email"
            name="name"
          />
        </label>
        <label>
          Password:
          <input
            className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5"
            type="password"
            name="name"
          />
        </label>
        <Button primary long margin="2rem" type="submit">
          Login
        </Button>
      </form>
    </LoginWrapper>
  );
};
