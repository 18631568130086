import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import React from "react";
import styled from "styled-components";
import * as Yup from "yup";
import {
  AuthorWrapper,
  FormInformationWrapper,
  FormWrapper,
} from "./FormElements";
import { motion } from "framer-motion";
import { Button } from "../common/Button";
import { BookImage } from "../storage/BookImage";
import { AuthorImageFirst } from "../storage/AuthorImageFirst";
import { AuthorImageSecond } from "../storage/AuthorImageSecond";
import imagePlaceholder from "../../images/image-placeholder-500x500.jpg";
import { Span } from "../campaign/elements";

const StyledErrorMessage = styled(ErrorMessage)`
  color: red;
  font-size: 12px;
  margin-bottom: 4px;
`;

const inputClassName =
  "rounded-none relative block w-full px-3 mb-4 py-2 border-2 border-b-4 border-gray-200 hover:border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-b-4 focus:border-yellow-500 focus:z-10 sm:text-sm sm:leading-5";

export const FormComponent = ({
  initialValues,
  handleSubmit,
  bookImage,
  setBookImage,
  authorImageFirst,
  setAuthorImageFirst,
  authorImageSecond,
  setAuthorImageSecond,
  showSecondAuthor,
  setShowSecondAuthor,
}) => {
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;

  const submitButtonName = window.location.pathname.split("/");
  const len = window.location.pathname.split("/").length;

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={Yup.object({
        book: Yup.object({
          title: Yup.string().required("Book Title is Required"),
        }),
        author: Yup.object({
          first: Yup.object({
            name: Yup.string().required("Author Name is Required"),
          }),
        }),
      })}
    >
      {({ values }) => (
        <div className="relative">
          <FormWrapper>
            <main>
              <FormInformationWrapper>
                <div className="rounded-md shadow-sm">
                  <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="w-48 m-3"
                    src={bookImage === null ? imagePlaceholder : bookImage}
                  />
                  <BookImage
                    image={bookImage !== null && bookImage}
                    setImage={setBookImage}
                  />

                  {/* title */}
                  <Span>Title</Span>
                  <Field
                    name="book.title"
                    type="name"
                    className={`appearance-none ${inputClassName}`}
                    placeholder="Name"
                  />
                  <StyledErrorMessage name="book.title" component="p" />

                  {/* Release Date */}
                  <Span>Release Date</Span>
                  <Field
                    name="book.releaseDate"
                    type="date"
                    className={`${inputClassName}`}
                    placeholder="Release Date"
                  />

                  {/* Genre */}
                  <Span>Genre</Span>
                  <Field
                    name="book.genre"
                    type="text"
                    className={`appearance-none ${inputClassName}`}
                    placeholder="Genre"
                  />

                  {/* Content Rating */}
                  <Span>Content Rating</Span>
                  <Field
                    as="select"
                    name="book.contentRating"
                    className={`${inputClassName}`}
                    placeholder="Content Rating"
                  >
                    <option defaultValue="E">E - Everyone</option>
                    <option value="E10plus">E10plus - Everyone 10+</option>
                    <option value="T">Teen - Age 13 and up</option>
                    <option value="M">Mature - Age 17 and up</option>
                    <option value="AO">Adult - Only 18+</option>
                    <option value="RP">RP - Review Pending</option>
                  </Field>

                  {/* Publisher */}
                  <Span>Publisher</Span>
                  <Field
                    name="book.publisher"
                    type="text"
                    className={`appearance-none ${inputClassName}`}
                    placeholder="Publisher"
                  />

                  {/* Book Format */}
                  <Span>Book Format</Span>
                  <Field
                    as="select"
                    name="book.bookFormat"
                    className={`${inputClassName}`}
                    placeholder="Book Format"
                  >
                    <option defaultValue="ebook">E-Book only</option>
                    <option value="hardCopy">Hardback only</option>
                    <option value="ebook-and-hardCopy">
                      Ebook and Hardback
                    </option>
                    <option value="paperBack">Paperback only</option>
                    <option value="ebook-and-paperBack">
                      Ebook &amp; Paperback
                    </option>
                  </Field>

                  {/* Pages */}
                  <Span>Pages</Span>
                  <Field
                    name="book.pages"
                    type="number"
                    className={`${inputClassName}`}
                    placeholder="Pages"
                  />
                  <div className="flex">
                    {/* Start Time */}
                    <div>
                      <Span>Start time</Span>
                      <Field
                        name="campaign.startTime"
                        type="date"
                        min={
                          submitButtonName[len - 1] === "update" ? "" : today
                        }
                        className={`${inputClassName}`}
                        placeholder="Start Time"
                      />
                    </div>
                    {/* End Time */}
                    <div>
                      <Span>End Time</Span>
                      <Field
                        name="campaign.endTime"
                        type="date"
                        min={values.campaign.startTime}
                        className={`${inputClassName}`}
                        placeholder="End Time"
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-6">
                  {/* Book Description */}
                  <Span>Book Description</Span>
                  <Field
                    name="book.description"
                    as="textarea"
                    className={`${inputClassName} h-60 mb-4`}
                    placeholder="Book Description"
                  />

                  {/* Sign Up as tour host */}
                  <Span>Sign Up as tour host</Span>
                  <Field
                    name="campaign.signUpLink"
                    type="text"
                    className={`${inputClassName} mb-4`}
                    placeholder="Sign Up as tour host"
                  />

                  {/* Buy Links */}
                  <Span>Buy Links</Span>
                  <FieldArray
                    name="book.buyLinks"
                    render={(arrayHelpers) => (
                      <div>
                        {values.book && values.book.buyLinks.length > 0 ? (
                          values.book.buyLinks.map((schedule, index) => (
                            <div className="flex" key={index}>
                              <Field
                                className={`${inputClassName} mx-2`}
                                name={`book.buyLinks[${index}].name`}
                                type="text"
                                placeholder="Name"
                              />
                              <Field
                                className={`${inputClassName} mx-2`}
                                name={`book.buyLinks[${index}].link`}
                                placeholder="Link"
                              />
                              <Button
                                margin="0 0 1rem 0"
                                type="button"
                                onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                              >
                                -
                              </Button>
                              <Button
                                margin="0 0 1rem 0.5rem"
                                primary
                                type="button"
                                onClick={() => arrayHelpers.insert(index, "")} // insert an empty string at a position
                              >
                                +
                              </Button>
                            </div>
                          ))
                        ) : (
                          <Button
                            primary
                            type="button"
                            onClick={() => arrayHelpers.push({})}
                          >
                            {/* show this when user has removed all tourSchedule from the list */}
                            Buy Links
                          </Button>
                        )}
                      </div>
                    )}
                  />
                </div>
              </FormInformationWrapper>

              <AuthorWrapper>
                {/* FIRST AUTHOR */}

                <div>
                  <Span>First Author</Span>
                  <motion.img
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    className="w-48 m-3"
                    src={
                      authorImageFirst === null || authorImageFirst === ""
                        ? imagePlaceholder
                        : authorImageFirst
                    }
                  />
                  <div>
                    <AuthorImageFirst
                      image={authorImageFirst !== null && authorImageFirst}
                      setImage={setAuthorImageFirst}
                    />
                    <Span>Name</Span>
                    <Field
                      name="author.first.name"
                      type="text"
                      className={`${inputClassName}`}
                      placeholder="Author Name"
                    />
                    <StyledErrorMessage
                      name="author.first.name"
                      component="p"
                    />
                    <Span>About</Span>
                    <Field
                      name="author.first.about"
                      as="textarea"
                      className={`${inputClassName} h-48`}
                      placeholder="About Author"
                    />
                  </div>
                </div>
                {/* SECOND AUTHOR */}

                <div className="my-auto">
                  <p
                    onClick={() => setShowSecondAuthor(!showSecondAuthor)}
                    className="text-center text-3xl rounded-full border border-indigo-800 p-2 cursor-pointer hover:bg-indigo-800 hover:text-white"
                  >
                    {!showSecondAuthor ? "+" : "-"}
                  </p>
                </div>

                {showSecondAuthor && (
                  <div>
                    <Span>Second Author</Span>
                    <motion.img
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="w-48 m-3"
                      src={
                        authorImageSecond === null || authorImageSecond === ""
                          ? imagePlaceholder
                          : authorImageSecond
                      }
                    />
                    <AuthorImageSecond
                      image={authorImageSecond !== null && authorImageSecond}
                      setImage={setAuthorImageSecond}
                    />
                    {/* Author Name */}
                    <Span>Name</Span>
                    <Field
                      name="author.second.name"
                      type="text"
                      className={`${inputClassName}`}
                      placeholder="Author Name"
                    />
                    <Span>About</Span>
                    <Field
                      name="author.second.about"
                      as="textarea"
                      className={`${inputClassName} h-48`}
                      placeholder="About Author"
                    />
                  </div>
                )}
              </AuthorWrapper>

              <div className="my-4">
                <Span>Tour Schedule</Span>
                <FieldArray
                  name="campaign.tourSchedule"
                  render={(arrayHelpers) => (
                    <div>
                      {values.campaign &&
                      values.campaign.tourSchedule.length > 0 ? (
                        values.campaign.tourSchedule.map((schedule, index) => (
                          <div className="flex" key={index}>
                            <Field
                              className={`${inputClassName} mx-2`}
                              name={`campaign.tourSchedule[${index}].date`}
                              type="date"
                              placeholder="Date"
                            />
                            <Field
                              className={`${inputClassName} mx-2`}
                              name={`campaign.tourSchedule[${index}].link`}
                              placeholder="Link"
                            />
                            <Field
                              className={`${inputClassName} mx-2`}
                              name={`campaign.tourSchedule[${index}].title`}
                              placeholder="Title"
                            />
                            <Field
                              className={`${inputClassName} mx-2`}
                              name={`campaign.tourSchedule[${index}].type`}
                              placeholder="Type"
                            />
                            <Button
                              margin="0 0 1rem 0"
                              type="button"
                              onClick={() => arrayHelpers.remove(index)} // remove a friend from the list
                            >
                              -
                            </Button>
                            <Button
                              margin="0 0 1rem 0.5rem"
                              primary
                              type="button"
                              onClick={() => arrayHelpers.insert(index, "")} // insert an empty string at a position
                            >
                              +
                            </Button>
                          </div>
                        ))
                      ) : (
                        <Button
                          primary
                          type="button"
                          onClick={() => arrayHelpers.push({})}
                        >
                          {/* show this when user has removed all tourSchedule from the list */}
                          Add a tour
                        </Button>
                      )}
                    </div>
                  )}
                />
              </div>
              {/* Tour Schedule */}

              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
              >
                {submitButtonName[len - 1]} campaign
              </button>
            </main>
          </FormWrapper>
        </div>
      )}
    </Formik>
  );
};
