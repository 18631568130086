import Information from "./pages/Information";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Landing from "./pages/Landing";
import Campaign from "./pages/Campaign";
import { firebase, projectFireStore } from "./config/firebase";
import { AppContext, CampaignContext } from "./store/context";
import { useEffect, useState } from "react";
import Login from "./pages/Login";
import About from "./pages/About";
import Resources from "./pages/Resources";
import { FooterComponent, NotFound } from "./components/common";
import { CampaignTemplate } from "./components/campaign";
import { AddCampaign } from "./components/edit/AddCampaign";
import { UpdateCampaign } from "./components/edit/UpdateCampaign";
import { Navbar } from "./components/common/Nav";
import { Policy } from "./components/policy/Policy";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [books, setBooks] = useState([]);
  const [currentCampaign, setCurrentCampaign] = useState(null);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    projectFireStore.collection("campaigns").onSnapshot((snapshot) => {
      setCampaigns(snapshot.docs.map((doc) => doc.data()));
    });
    projectFireStore
      .collection("books")
      .orderBy("createdAt", "desc")
      .onSnapshot((snapshot) => {
        setBooks(snapshot.docs.map((doc) => doc.data()));
      });
    projectFireStore.collection("bookAuthor").onSnapshot((snapshot) => {
      setAuthors(snapshot.docs.map((doc) => doc.data()));
    });
  }, [isLoggedIn]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        setIsLoggedIn(true);
        setUser(user);
        setIsLoading(false);
      } else {
        setUser({});
        setIsLoggedIn(false);
        setIsLoading(false);
      }
    });
  }, []);

  const campaignValues = {
    campaigns,
    setCampaigns,
    currentCampaign,
    setCurrentCampaign,
    books,
    authors,
    formData,
    setFormData,
    isLoading,
    setIsLoading,
  };

  return (
    <div>
      <Router>
        <AppContext.Provider value={[isLoggedIn, user]}>
          <Navbar />
          <Switch>
            <Route exact path="/">
              <Landing />
            </Route>
            <Route exact path="/information">
              <Information />
            </Route>
            <Route exact path="/login">
              <Login />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/resources">
              <Resources />
            </Route>
            <Route exact path="/policy">
              <Policy />
            </Route>

            <CampaignContext.Provider value={campaignValues}>
              <Switch>
                <Route exact path="/campaigns/add" component={AddCampaign} />
                <Route
                  exact
                  path="/campaigns/:title/update"
                  component={UpdateCampaign}
                />
                <Route path="/campaigns/:title" component={CampaignTemplate} />
                <Route exact path="/campaigns" component={Campaign} />
                <Route>
                  <NotFound />
                </Route>
              </Switch>
            </CampaignContext.Provider>
          </Switch>
          <FooterComponent />
        </AppContext.Provider>
      </Router>
    </div>
  );
}

export default App;
