import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import tw from "twin.macro";
import { Button } from "./Button";
import Logo from "../../images/logo.svg";

const StyledBurger = styled.div`
  width: 2rem;
  height: 1rem;
  position: absolute;
  top: 18px;
  right: 20px;
  z-index: 9999;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => (open ? "#333" : "#ccc")};
    border-radius: 10px;
    transform-origin: 32%;
    transition: all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    position: relative;
    overflow: hidden;
    &:nth-child(1) {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

export const NavLink = styled(Link)`
  ${tw`text-gray-700 text-xl md:text-sm text-center bg-yellow-100 hover:bg-yellow-200 sm:px-4 w-11/12  py-1 m-2 rounded-sm hover:cursor-pointer border-b-2 border-gray-50 active:border-yellow-500`};
`;

const Burger = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledBurger
        open={open}
        onClick={() => {
          if (navigator.vibrate) {
            window.navigator.vibrate(30);
          }
          setOpen(!open);
        }}
      >
        <div />
        <div />
      </StyledBurger>
      <RightNav open={open} setOpen={setOpen} />
    </>
  );
};

const Ul = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-around;
  flex-flow: row nowrap;
  border-radius: 0 0 10px 10px;
  overflow: hidden;

  @media (max-width: 768px) {
    flex-flow: column nowrap;
    position: fixed;
    transform: ${({ open }) => (open ? "translateY(0)" : "translateY(-100%)")};
    opacity: ${({ open }) => (open ? "1" : "0")};
    top: 0;
    right: 0;
    height: auto;
    width: 100%;
    padding-bottom: 1rem;
    padding-top: 3rem;
    transition: all 0.1s ease-in-out;
    background: rgba(255, 255, 255, 0.99);
    box-shadow: 0 1px 8px 0 rgba(31, 39, 135, 0.37);
    ${NavLink} {
      margin: 1rem auto;
      text-align: center;
    }
  }
`;

const RightNav = ({ open, setOpen }) => {
  return (
    <Ul open={open}>
      <NavLink onClick={() => setOpen(!open)} to="/about">
        About
      </NavLink>
      <NavLink onClick={() => setOpen(!open)} to="/campaigns">
        Campaigns
      </NavLink>
      <NavLink onClick={() => setOpen(!open)} to="/information">
        Information
      </NavLink>
      <NavLink onClick={() => setOpen(!open)} to="/resources">
        Resources
      </NavLink>
    </Ul>
  );
};

const Nav = styled.nav`
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 1px 4px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(12px);
  border-radius: 0 0 10px 10px;
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #f1f1f1;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  z-index: 99999;
  top: 0;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  a {
    @media (max-width: 768px) {
      margin-right: 3rem;
    }
  }
`;

export const Navbar = () => {
  return (
    <Nav>
      <Link to="/">
        <img className="w-20 " alt="logo" src={Logo}></img>
      </Link>
      <Burger />
      <a
        href="mailto:thebookpundits@gmail.com"
        target="_blank"
        rel="noreferrer"
      >
        <Button>Contact us</Button>
      </a>
    </Nav>
  );
};
