import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-64">
      <h1 className="text-center text-2xl">This page does not exist 😮</h1>
      <p>
        please try starting again from the{" "}
        <Link className="text-yellow-500 underline" to="/">
          home page
        </Link>
      </p>
    </div>
  );
};
