import { motion } from "framer-motion";
import React, { useState } from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import styled from "styled-components";
import tw from "twin.macro";

export const FAQItem = ({ question, description }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <FAQWrapper>
        <hr className="m-2" />
        <div
          className="flex justify-between mx-4 relative"
          onClick={() => setOpen((prev) => !prev)}
        >
          <div className="flex">
            <Dot open={open} />

            <section className="">
              <h3 className={`text-gray-900 text-sm font-bold`}>{question}</h3>
              {open && (
                <motion.p
                  initial={{ y: -10 }}
                  animate={{ y: 0 }}
                  className="text-gray-600 text-sm my-2"
                >
                  {description}
                </motion.p>
              )}
            </section>
          </div>
          <div>{open ? <IoIosArrowUp /> : <IoIosArrowDown />}</div>
        </div>
      </FAQWrapper>
    </>
  );
};

const FAQWrapper = styled.main`
  ${tw`max-w-xl m-2 mx-auto text-left cursor-pointer select-none overflow-hidden `}
  -webkit-tap-highlight-color: transparent;
`;

const Dot = styled.div`
  ${tw`absolute bg-yellow-500 w-1 h-1 transition-all transform scale-50 mx-2 p-2 rounded-full`};
  margin-top: 1px;
  left: -25px;
  opacity: ${(props) => (props.open ? "1" : "0")};
`;

const data = [
  {
    question: "What is a book tour?",
    description:
      "A book tour or campaign is an organized event, where we schedule multiple blog stops for a book. This way you can showcase and take your book to a wider audience. This is like a multi city concert tour by A.R.Rahman, but your book is the star here.",
  },
  {
    question: "Do you publish books?",
    description: "No.",
  },
  {
    question: "Do you help authors write books?",
    description:
      "We do offer editing and proofreading services. Please contact us to know more.",
  },
  {
    question: "Do you reimburse amazon book purchases?",
    description:
      "It is against Amazon policy to reimburse product purchases in exchange for promotion. So we do not violate those policies. Our Authors will send out books to reviewers.",
  },
  {
    question: "How will influencers be paid?",
    description:
      "Based on the Influencer's content, we make final payments after the last date of the tour. For more information, contact us. ",
  },
];

export const FAQComponent = () => {
  return (
    <div className="my-8">
      <h1 className="text-gray-800 text-center mb-8 text-2xl font-bold font-display">
        FREQUENTLY ASKED QUESTIONS
      </h1>
      {data.map((item, key) => (
        <FAQItem
          key={key}
          question={item.question}
          description={item.description}
        />
      ))}
    </div>
  );
};
