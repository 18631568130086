import React from "react";
import { CTAButton } from "./Button";
import styled from "styled-components";
import tw from "twin.macro";

export const CTAWrapper = styled.main`
  ${tw`flex flex-col sm:flex-row w-11/12 justify-around items-center container text-center sm:text-left lg:mx-auto py-12 max-w-4xl`}

  span {
    ${(props) => {
      props.author ? tw`text-green-600` : tw`text-yellow-600`;
    }}
  }
`;

export const CTABackground = styled.div`
  ${tw`mb-16`}
  ${(props) => (props.author ? tw`bg-yellow-100` : tw`bg-green-100`)}
`;

export const CTAComponent = ({ author }) => {
  return (
    <CTABackground author={author}>
      <CTAWrapper>
        <h1 className="sm:text-3xl md:text-4xl text-2xl font-bold mb-4">
          {author ? "Find your Readers." : "Discover more reads."}
          <br />
          <span className={author ? "text-yellow-600" : "text-green-600"}>
            Join us today!
          </span>
        </h1>
        <a
          href={
            author
              ? "https://docs.google.com/forms/d/e/1FAIpQLScevfmXoA4KrK9jma0_4stATy9MR3dCRqPWlSu5vBU6nBnzJA/viewform"
              : "https://docs.google.com/forms/d/e/1FAIpQLScd3vFijgT-kEpIhrNHFxLX4GMRy5bBUC2zWShIrI135-bUlw/viewform"
          }
          target="_blank"
          rel="noreferrer"
        >
          <CTAButton primary long author={author}>
            {author ? "Find your Readers" : "Become a host"}
          </CTAButton>
        </a>
      </CTAWrapper>
    </CTABackground>
  );
};
